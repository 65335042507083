import $ from 'jquery';
import ScrollHint from './scroll-hint.js';

// smooth scroll

$(function () {
  $('a[href^="#"]')
    .not('.slider_nav a')
    .on('click', function () {
      var adjust = 0;
      var speed = 400;
      var href = $(this).attr('href');
      var target = $(href == '#' || href == '' ? 'html' : href);
      var position = target.offset().top + adjust;
      $('body,html').animate({ scrollTop: position }, speed, 'swing');
      return false;
    });
});

//Window height
$(window).on('load resize', function () {
  var hdr = $('.main-header');
  var hdrHeight = hdr.outerHeight(true);
  var h = $(window).height();
  $('#kv .inner').css('height', h - hdrHeight);
});

//accordion menu
$(function () {
  $('.faq_list dt').on('click', function () {
    $(this).next().slideToggle();
    $(this).toggleClass('open');
  });
});

//nav
$(function () {
  $('.menuTrigger').on('click', function () {
    $(this).toggleClass('open');
    $('.head-nav').toggleClass('open');
  });
  $('header .nav a').on('click', function () {
    $('.head-nav').toggleClass('open');
  });
});

//footer
$(function () {
  var btn = $('.foot_link_area');
  $(window).on('load scroll', function () {
    if ($(this).scrollTop() > 100) {
      btn.addClass('active');
    } else {
      btn.removeClass('active');
    }
  });
  $('.trig').on('click', function () {
    btn.removeClass('active');
    $(window).off('scroll');
    $(this).css('display', 'none');
  });
});

$('.carousel01').owlCarousel({
  items: 5,
  loop: true,
  margin: 40,
  center: true,
  autoplay: true,
  autoplayTimeout: 2000,
  autoplaySpeed: 2000,
  dots: false,
  mouseDrag: false,
  touchDrag: false,
  responsive: {
    0: {
      items: 2,
    },
    767: {
      items: 5,
    },
  },
});

var $owl = $('.carousel02');
$owl.owlCarousel({
  items: 1,
  callbacks: true,
  URLhashListener: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplaySpeed: 2000,
  loop: true,
  responsive: {
    0: {
      nav: true,
    },
    767: {
      nav: false,
    },
  },
});

$(function () {
  $owl.on('changed.owl.carousel', function (event) {
    // Do something
    var cont = $owl.find('li').eq(event.item.index);
    var hash = cont.data('hash');

    $('.slider_nav li').removeClass('active');
    $('.slider_nav li[data-hash="' + hash + '"]').toggleClass('active');
  });
});

$('.carousel03').owlCarousel({
  items: 1,
  dots: true,
  nav: true,
  autoplayHoverPause: true,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplaySpeed: 2000,
});

new ScrollHint('.js-scrollable', {
  suggestiveShadow: true,
  i18n: {
    scrollable: '横にスクロール',
  },
});

// scroll animation
function scrollChk() {
  $('.fadeIn').each(function () {
    var targetElement = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > targetElement - windowHeight + 100) {
      $(this).addClass('scrollin');
    } else {
      $(this).removeClass('scrollin');
    }
  });
}
$(window).on('load scroll', function () {
  scrollChk();
});
$('body').on('touchmove', function () {
  scrollChk();
});
